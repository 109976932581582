import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import MargueeWrapper from './MargueeWrapper';
import telegram_icon from '../assets/images/img/sm_icon/telegram.svg';
import whatsapp_icon from '../assets/images/img/sm_icon/whatsapp.svg';
import vk_icon from '../assets/images/img/sm_icon/vk.svg';
import inst_icon from '../assets/images/img/sm_icon/instagram.svg';

function Footer() {
  const [iframeSrc, setIframeSrc] = useState(null);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIframeSrc(
        'https://yandex.ru/map-widget/v1/?ll=37.859691%2C55.924892&mode=poi&poi%5Bpoint%5D=37.857553%2C55.927064&poi%5Buri%5D=ymapsbm1%3A%2F%2Forg%3Foid%3D60176524188&utm_campaign=desktop&utm_medium=search&utm_source=maps&z=16.78'
      );
    }, 500);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <footer className="pb-0 bg-black text-white mb-0 mt-5">
      <MargueeWrapper />
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <a target="_blank" href="https://t.me/studioflower" rel="noreferrer">
              <img className="sm-icon mb-3 me-1" src={telegram_icon} alt="Telegram" />
            </a>
            <a target="_blank" href="https://wa.me/message/MUWXIHXASF6EA1" rel="noreferrer">
              <img className="sm-icon mb-3 me-1" src={whatsapp_icon} alt="WhatsApp" />
            </a>
            <a target="_blank" href="https://vk.com/floristelru" rel="noreferrer">
              <img className="sm-icon mb-3 me-1" src={vk_icon} alt="VK" />
            </a>
            <a target="_blank" href="https://www.instagram.com/floristel.ru" rel="noreferrer">
              <img className="sm-icon mb-3 me-1" src={inst_icon} alt="Instagram" />
            </a>
            <p>Ежедневно 9:00 - 21:00</p>
            <p>ТЦ "Болшево" МО, г. Королёв, пл. Станционная, д. 4, 1 этаж</p>
            <a target="_blank" href="tel:+79932800156" rel="noreferrer">
              <p>+7 993 280 01 56</p>
            </a>
            <a target="_blank" href="mailto:floristelru@yandex.ru" rel="noreferrer">
              <p>info@floristel.ru</p>
            </a>
          </div>

          <div className="col-md-4">
            <Link to="/delivery"><p>Доставка</p></Link>
            <Link to="/faq"><p>Частые вопросы</p></Link>
            <Link to="/freshnote"><p>Памятка свежести</p></Link>
            <a target="_blank" href="https://www.avito.ru/korolev/vakansii/prodavets_florist_4283590102" rel="noreferrer"><p>Работа у нас</p></a>
          </div>

          <div className="col-md-4" style={{ position: 'relative', overflow: 'hidden' }}>
            <a
              href="https://yandex.ru/maps/org/floristel/60176524188/?utm_medium=mapframe&utm_source=maps"
              style={{ color: '#eee', fontSize: '12px', position: 'absolute', top: '0px' }}
              target="_blank" rel="noreferrer"
            >
              Floristel
            </a>
            <a
              href="https://yandex.ru/maps/20728/korolev/category/flower_shop/184108325/?utm_medium=mapframe&utm_source=maps"
              style={{ color: '#eee', fontSize: '12px', position: 'absolute', top: '14px' }}
              target="_blank" rel="noreferrer"
            >
              Магазин цветов в Королёве
            </a>
            <a
              href="https://yandex.ru/maps/20728/korolev/category/flowers_and_bouquets_delivery/184108319/?utm_medium=mapframe&utm_source=maps"
              style={{ color: '#eee', fontSize: '12px', position: 'absolute', top: '28px' }}
              target="_blank" rel="noreferrer"
            >
              Доставка цветов и букетов в Королёве
            </a>
            
            {iframeSrc && (
              <iframe
                className="col-sm-12"
                title="ya.maps"
                src={iframeSrc}
                width="100%"
                height="200"
                frameBorder="10"
                allowFullScreen={true}
                loading="lazy"
                style={{ position: 'relative' }}
              ></iframe>
            )}
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <p className="copyright col-md-12 text-center m-4 d-flex justify-content-center">🌸 Floristel {new Date().getFullYear()}</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;